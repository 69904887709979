import { useCallback, useEffect, useMemo, useState, createContext } from 'react'
import { debounce, find } from 'lodash'
import { useHotkeys } from 'react-hotkeys-hook'
import { DateTime } from 'luxon'

import PageHeader from 'src/@components/page/PageHeader'
import Table from 'src/@components/table/Table'
import EmptyLoading from 'src/@components/page/EmptyLoading'
import EmptyData from 'src/@components/page/EmptyData'
import EmptySearch from 'src/@components/page/EmptySearch'
import Form from 'src/@components/form/Form'
import { useLocation, useParams, useNavigate } from 'react-router-dom'
import { useSettings } from '../../settings/SettingsApi'
import clsx from 'clsx'
import { v4 as uuidv4, validate } from 'uuid'
import { useProfile } from 'src/profiles/ProfilesApi'
import { supabase } from 'src/supabase'
import { useAuth } from 'src/auth/Auth'
import { useTranslation } from 'react-i18next'
import { useFolder } from 'src/folders/FoldersApi'

export const PageContext = createContext()

const persistentSorts = (columns) => {
  const { t } = useTranslation()
  const sorts = JSON.parse(localStorage.getItem('sorts'))

  if (sorts) {
    return sorts.map((sort) => {
      const column = find(columns, { field: sort.field })

      if (column) {
        return {
          ...sort,
          key: column.field,
          label: t(`folders.fields.${column.field}`),
          field: column.field,
          sortField: column.sortField,
          icon: column.headerComponentParams?.icon,
        }
      }
    })
  }

  return []
}

const persistentFilters = (columns) => {
  const { t } = useTranslation()
  const filters = JSON.parse(localStorage.getItem('filters'))

  if (filters) {
    return filters.map((filter) => {
      const column = find(columns, { field: filter.key })

      if (column) {
        return {
          ...filter,
          label: t(`folders.fields.${column.field}`),
          key: column.field,
          field: column.field,
          icon: column.headerComponentParams?.icon,
          filterOption: column.filterOption
        }
      }
    })
  }

  return []
}

export default function Page({
  compact = false,
  icons,
  translateKey,

  columns: _columns = [],
  menus = [],
  sections = [],

  FormInitialValues,
  FormComponent,
  ListComponent: ListComponent,
  PageHeaderExtra,
  directInsert,

  queryParams = {},
  syncRequests,
  useCounts,
  useQuery,
  useUpdate,
  useRemove,
  useInsert,
}) {
  const { session } = useAuth()
  const { pathname } = useLocation()
  const { data: settings } = useSettings()
  const { data: profile } = useProfile()
  const { folder } = useParams()
  const navigate = useNavigate();
  const [search, setSearch] = useState('')
  const [initialValues, setInitialValues] = useState({})
  const [isFormOpen, setFormOpen] = useState(false)
  const [isHovered, setIsHovered] = useState(false)

  const section = useMemo(() => find(sections, { path: pathname }) || { value: '*' }, [pathname])

  const columns = useMemo(() => {
    return _columns
      .filter(column => column.filter ? column.filter({ session, profile, section, settings }) : true)
  }, [section])

  const [sorts, setSorts] = useState(persistentSorts(columns))
  const [filters, setFilters] = useState(persistentFilters(columns))
  const [filterDate, setFilterDate] = useState(DateTime.now())

  const sectionsCounts =
    useCounts &&
    useCounts({
      sections: sections.filter(s => s.value !== 'completed'),
      search,
      sorts,
      filters,
      filterDate
    })

  const counts = useMemo(
    () => sections.filter(s => s.value !== 'completed').reduce((a, section, index) => ({ ...a, [section.value]: sectionsCounts[index].data }), {}),
    [sectionsCounts]
  )

  const { data, isFetched, isFetchedAfterMount, isFetching, refetch } = useQuery({
    section: section.value,
    queryParams,
    search,
    sorts,
    filters,
    filterDate
  })
  const { mutate: insert } = useInsert({ queryParams })
  const { mutate: update } = useUpdate({ queryParams })
  const { mutate: remove } = useRemove({ queryParams })

  const throttledRefetch = useCallback(debounce(refetch, 700), [])

  // useEffect(() => {
  //   if (translateKey === 'folders') {
  //     const channel = supabase
  //       .channel('folders-db-changes')
  //       .on(
  //         'postgres_changes',
  //         {
  //           event: '*',
  //           schema: 'public',
  //           table: 'folders'
  //         },
  //         (payload) => {
  //           refetch()
  //         }
  //       )
  //       .subscribe()

  //     return () => {
  //       if (channel) {
  //         channel.unsubscribe()
  //       }
  //     }
  //   }
  // }, [])

  useEffect(() => {
    if (isFetchedAfterMount) {
      throttledRefetch()
    }
  }, [search])
  
  useEffect(() => {
    if (isFetchedAfterMount) {
      refetch()

      // sectionsCounts.forEach((sectionCount) => {
      //   sectionCount.refetch()
      // })
    }

    localStorage.setItem('filters', JSON.stringify(filters.filter(f => !!f).map(f => ({ key: f.key, value: f.value }))))
    localStorage.setItem('sorts', JSON.stringify(sorts.filter(f => !!f).map(f => ({ field: f.field, ascending: f.ascending }))))
  }, [sorts, filters, filterDate])

  useHotkeys(
    'alt+c',
    () => {
      if (document.activeElement.classList.contains('ant-drawer')) {
        return false
      }
      openForm()
    },
    { enabled: settings.shortcuts_enabled }
  )

  useEffect(() => {
    const fetchFolder = async () => {
      const { data, error } = await useFolder(folder)
      if (data) {
        openForm(data)
      }
    }
    
    if (!initialValues.id && folder) {
      fetchFolder()
    }
  }, [folder])

  const openForm = (values) => {
    if (values) {
      if (profile.universus) {
        if (settings.permissions['read-folders'] && values.pydio_id) {
          return navigate((section.value === "completed" || values.work.status === "ready" ? "/report" : '/transcription') + '/' + values.pydio_id)
        } else {
          return;
        }
      } else {
        return navigate('/order/' + values.pydio_id)
      }
    }

    if (directInsert) {
      insert({ id: uuidv4(), ...FormInitialValues({ profile }) })
    } else {
      setInitialValues({
        ...values,
        ...FormInitialValues({ profile, values }),
        ...section.initialValues
      })

      setFormOpen(true)
    }

    if (values?.pydio_id && !pathname.includes(values.pydio_id)) {
      navigate(pathname + '/' + values.pydio_id)
    }
  }

  const closeForm = () => {
    document.activeElement.blur()
    if (folder) {
      navigate(pathname.substring(0, pathname.lastIndexOf('/')))
    }
    setFormOpen(false)
  }

  return (
    <PageContext.Provider value={{ insert, update, remove, sorts, setSorts, translateKey, openForm, menus }}>
      {FormComponent && (
        <Form
          translateKey={translateKey}
          icons={icons}
          syncRequests={syncRequests}
          menus={menus}
          FormComponent={FormComponent}
          insert={insert}
          update={update}
          closeForm={closeForm}
          isOpen={isFormOpen}
          remove={remove}
          initialValues={initialValues}
        ></Form>
      )}

      <div
        className={clsx('flex grow flex-col', compact && ' gap-1.5')}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <PageHeader
          icons={icons}
          translateKey={translateKey}
          PageHeaderExtra={PageHeaderExtra}
          compact={compact}
          data={data}
          isVisible={isHovered}
          columns={columns}
          sections={sections}
          section={section}
          counts={counts}
          search={search}
          setSearch={setSearch}
          sorts={sorts}
          setSorts={setSorts}
          filters={filters}
          setFilters={setFilters}
          openForm={openForm}
        />
        <div
          className={clsx(
            compact && 'h-max-48 overflow-hidden rounded-lg border border-base',
            compact && !!data.length && 'border-solid',
            compact && !data.length && 'border-dashed',
            !compact && 'relative flex grow basis-0 flex-col'
          )}
        >
          {!ListComponent && (
            <>
              {(!compact || !!data.length) && (
                <Table
                  compact={compact}
                  data={data}
                  openForm={openForm}
                  isFormOpen={isFormOpen}
                  section={section}
                  // count={counts[section.value]}
                  columns={columns}
                />
              )}

              {isFetched && !isFetching && !data?.length && !!search.length && (
                <EmptySearch compact={compact} search={search} />
              )}

              {isFetching && !data?.length && (
                <EmptyLoading icons={icons} translateKey={translateKey} compact={compact} section={section} />
              )}

              {isFetched && !isFetching && !data?.length && !search.length && (
                <EmptyData
                  icons={icons}
                  translateKey={translateKey}
                  compact={compact}
                  openForm={openForm}
                  section={section}
                />
              )}
            </>
          )}

          {!!ListComponent && (
            <ListComponent
              setFilterDate={setFilterDate}
              filterDate={filterDate}
            
              isFormOpen={isFormOpen}
              section={section}
              data={data}
              count={counts[section.value]}
              openForm={openForm}
            />
          )}
        </div>
      </div>
    </PageContext.Provider>
  )
}
