import { duotone, light, regular, solid, thin } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, Button, Cascader, Checkbox, Dropdown, Input, List, Modal, Popover, Select, Slider, Tabs, Tag, Tooltip, Typography } from "antd";
import { cloneElement, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Avatar from "src/@components/Avatar";
import Label from "src/@components/Label";
import { useLocation, useParams, useNavigate } from 'react-router-dom'
import Textarea from "src/@components/form/Textarea";
import PageHeader from "src/@components/page/PageHeader";
import { supabase } from "src/supabase";
import logo from "src/assets/universus-black.png"
import { useProfile, useTranscriber } from "src/profiles/ProfilesApi";
import clsx from "clsx";
import Form from "src/@components/form/Form";
import FoldersForm, { FoldersFormInitialValues } from "src/folders/FoldersForm";
import { useFolder, useRemoveFolder, useUpdateFolder } from "src/folders/FoldersApi";
import { useSettings } from "src/settings/SettingsApi";
import { useQueryClient } from "@tanstack/react-query";
import { useUI } from "src/layout/UI";
import { PDFViewer } from "src/transcription/Files";
import { set } from "lodash";
import { useAuth } from "src/auth/Auth";
import Player from "src/transcription/Player";
import ducore from 'src/assets/ducore.png'
import voice from 'src/assets/voice.png'
import reportImg from 'src/assets/report.png'

const { APP_API_URL } = import.meta.env

export default function Transcription({ reviewLoading, transcription, setTranscription, openChatForReview, loadParent, folder, setShowLeft, showLeft, goBack, dictation }) {
    const { data: settings } = useSettings()
    const { data: profile } = useProfile()
    const { session } = useAuth()
    const location = useLocation()
    const [downloadLoading, setDownloadLoading] = useState(false)
    const [uploadLoading, setUploadLoading] = useState(false)
    const [finishLoading, setFinishLoading] = useState(false)
    const [activeTab, setActiveTab] = useState(location.pathname.includes('report') ? 'report' : 'transcription')
    const [gabarits, setGabarits] = useState([])
    const [gabaritsOpen, setGabaritsOpen] = useState(false)
    const [loadingGabarits, setLoadingGabarits] = useState(true)
    const [archiveEmails, setArchiveEmails] = useState([])
    const [invoiceUnit, setInvoiceUnit] = useState(0)
    const [archiveComment, setArchiveComment] = useState('')
    const [archiveModal, setArchiveModal] = useState(false)
    const { messageApi } = useUI()
    const navigate = useNavigate();
    const queryClient = useQueryClient()
    const { data: transcriber } = useTranscriber()
    const { t } = useTranslation()

    const [askReview, setAskReview] = useState(false)
    const [archiveLoading, setArchiveLoading] = useState(false)
    const [fileArchive, setFileArchive] = useState(null)
    const [reportIsCorrect, setReportIsCorrect] = useState(false)
    const [hasSuggest, setHasSuggest] = useState(false)
    const [report, setReport] = useState(false)
    const [pspdfkit, setPspdfkit] = useState(null)
    const fileInputRef = useRef(null)
    const fileInputReviewRef = useRef(null)
    const fileInputFinishRef = useRef(null)

    const decline = async () => {
        loadParent(true)
        const { data, error } = await fetch(`${APP_API_URL}/decline-folder/${folder.id}`, {
            headers: {
                Authorization: `Bearer ${session.access_token}`,
            },
        }).then(res => res.json())

        if (error) {
            messageApi.open({
                type: 'error',
                content: error.message,
                duration: 4,
            })
        } else {
            queryClient.setQueryData(['transcriber'], (old) => ({
                ...old,
                current: null
            }))

            queryClient.setQueryData(['folders', 'available'], (old) => ((old || []).map(folder => folder.id === data.id ? {
                ...folder,
                work: {
                    ...folder.work,
                    status: 'available'
                }
            } : folder)))

            // Redirect because the Navigation.jsx redirection is not handled for administrators
            if (settings?.permissions['read-folders']) {
                navigate('/available')
            }
        }

    }

    useEffect(() => {
        if (folder && profile.universus) {
            const fetchGabarits = async (group) => {
                const { data, error } = await supabase.storage.from('folders').list(`${folder.company.id}/${folder.folder_id}/${group}`)
                setLoadingGabarits(false)
                if (data) {
                    for (const gabarit of data) {
                        if (!gabarit.id) {
                            fetchGabarits(group + '/' + gabarit.name)
                        } else {
                            setGabarits(prevGabarits => [
                                ...prevGabarits,
                                {
                                    group,
                                    gabarit
                                }
                            ])
                        }
                    }
                }
            }

            const fetchArchiveEmails = async () => {
                const { data, error } = await supabase.from(
                    "profiles",
                ).select(
                    "user_id, email, push_token, profile_settings(notification_report)",
                ).eq("company_id", folder.company.id).or(
                    `role.eq.administrator,id.eq.${folder.doctor.profile.id}`,
                );

                if (!error) {
                    const emails = data.filter((p) =>
                        p.profile_settings[0].notification_report && !!p.email
                    ).map(p => p.email)

                    setArchiveEmails(emails)
                }
            }

            if (folder.work.status === 'ongoing') {
                fetchGabarits('work/gabarits')
            }

            if (['ready', 'completed'].includes(folder.work.status)) {
                fetchArchiveEmails()
            }
        }
    }, [folder])

    const gabaritsCascader = useMemo(() => transformGabarits(gabarits), [gabarits]);

    const downloadGabarit = (items) => {
        setGabaritsOpen(false)
        const file = gabarits.find(g => g.gabarit.name.includes(items[items.length - 1]))

        if (file) {
            downloadFile(folder.company.id + '/' + folder.folder_id + '/' + file.group + '/' + file.gabarit.name)
        }
    }

    const canUseGenerator = useMemo(() => {
        return gabarits.find(g => g.gabarit.name.includes('_TEMPLATE_')) && transcriber.generator && false
    }, [gabarits, transcriber])

    const finish = async ({ target: { files } }) => {
        if (files.length) {
            const file = files[0]

            setFinishLoading(true)
            const formData = new FormData()

            if (file) {
                formData.append(`report`, file, file.name)
            }

            if (askReview) {
                formData.append(`askReview`, true)
            }

            if (transcription) {
                formData.append(`transcription`, transcription)
            }

            const { data, error } = await fetch(`${APP_API_URL}/finish-folder/${folder.id}`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${session.access_token}`,
                },
                body: formData,
            }).then(res => res.json())

            setFinishLoading(false)

            if (data && !error) {
                messageApi.open({
                    type: 'success',
                    content: "Dossier terminé",
                    duration: 4,
                })

                const inprogress = queryClient.getQueryData(['folders', 'inprogress'])
                if (inprogress) {
                    queryClient.setQueryData(['folders', 'inprogress'], (old) => old.filter(d => {
                        return d.id !== folder.id
                    }))
                }

                const available = queryClient.getQueryData(['folders', 'available'])
                if (available) {
                    queryClient.setQueryData(['folders', 'available'], (old) => old.filter(d => {
                        return d.id !== folder.id
                    }))
                }

                navigate('/available')
            } else {
                messageApi.open({
                    type: 'error',
                    content: error.message,
                    duration: 4,
                })
            }
        }
    }

    const archive = async () => {
        setArchiveLoading(true)
        const formData = new FormData()

        if (fileArchive && !reportIsCorrect) {
            formData.append(`report`, fileArchive, fileArchive.name)
        }

        if (!!archiveComment.trim().length) {
            formData.append(`comments_report`, archiveComment)
        }

        formData.append(`invoice_unit`, invoiceUnit)

        const { data, error } = await fetch(`${APP_API_URL}/archive-folder/${folder.id}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${session.access_token}`,
            },
            body: formData,
        }).then(res => res.json())

        setArchiveLoading(false)
        if (data && !error) {
            messageApi.open({
                type: 'success',
                content: "Dossier envoyé au client",
                duration: 4,
            })

            const validation = queryClient.getQueryData(['folders', 'validation'])
            if (validation) {
                queryClient.setQueryData(['folders', 'validation'], (old) => old.filter(d => {
                    return d.id !== folder.id
                }))
            }

            navigate('/validation')
            setArchiveModal(false)
        } else {
            messageApi.open({
                type: 'error',
                content: error.message,
                duration: 4,
            })
        }
    }

    const handleFileChange = async ({ target: { files } }) => {
        if (files.length) {
            setFileArchive(files[0])
        }
    }

    useEffect(() => {
        const getReportUri = async (path) => {
            const {
                data,
                error
            } = await supabase.storage.from('folders').createSignedUrl(path, 60)

            if (data?.signedUrl) {
                setReport(data.signedUrl)
            }
        }

        const path = folder.report_path || folder.work?.report_path
        const pspdfkit = folder.report_pspdfkit || folder.work?.report_pspdfkit

        if (path) {
            getReportUri(path)
        }

        setPspdfkit(pspdfkit)
    }, [folder])

    const downloadFile = async (path) => {
        setDownloadLoading(true)
        setLoadingGabarits(true)
        const { data, error } = await supabase
            .storage
            .from('folders')
            .download(path)

        if (data) {
            const blobUrl = window.URL.createObjectURL(data);
            const link = document.createElement('a');
            link.href = blobUrl;
            link.download = path.split('/')[path.split('/').length - 1]; // Set your desired filename and extension here
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(blobUrl);
        } else {
            messageApi.open({
                type: 'error',
                content: error.message,
                duration: 4,
            })
        }

        setDownloadLoading(false)
        setLoadingGabarits(false)
    }

    const ongoing = folder && (folder.work?.status === 'ongoing' || transcriber?.current?.id === folder.id)

    const menu = useMemo(() => {
        let value = []
        if (folder.work?.status === 'ongoing' && settings?.permissions && settings?.permissions['read-folders']) {
            value.push({
                key: 'decline',
                label: (
                    <Typography.Text>Désassigner le dossier</Typography.Text>
                ),
            })
        }

        return value
    }, [folder])

    const tabsItems = useMemo(() => {
        let items = []

        if (transcription) {
            items.push(
                { key: 'transcription', label: 'Transcription' },
            )
        }

        if (report) {
            items.push(
                { key: 'report', label: 'Rapport' }
            )
        }

        if (items.length === 1) {
            setActiveTab(items[0].key)
        }

        return items
    }, [report, transcription])

    return (
        <>
            {folder && <>
                {!profile.universus && folder.status !== 'completed' && <PreviewTranscription />}

                {(profile.universus || folder.status === 'completed') && <div
                    className={'flex items-center justify-between border-0 border-b border-solid border-layout pr-3 pl-0'}
                >
                    {!showLeft && <div className="flex gap-2 items-center px-2">
                        {settings?.permissions && settings?.permissions['read-folders'] && <Button onClick={goBack} type="text" shape="circle" icon={<FontAwesomeIcon icon={solid('arrow-left')} size="lg" />} />}
                        <Button onClick={() => setShowLeft(true)} type="text" shape="circle" icon={<FontAwesomeIcon icon={solid('ellipsis')} size="lg" />} />
                    </div>}

                    <div className="flex items-center grow gap-4 px-4">
                        <Avatar size={30} profile={folder.doctor.profile} style={{ transform: "scale(1.2)" }} />
                        <div className="flex flex-col" style={{ userSelect: 'none', cursor: 'pointer', position: 'relative', top: 2 }}>
                            <Typography.Text style={{ fontSize: '12px', fontWeight: '400' }}>
                                {folder.company.name}
                            </Typography.Text>
                            <Typography.Text style={{ fontSize: '14px', fontWeight: '300', position: 'relative', bottom: 6 }}>
                                {folder.doctor.profile.gender === 'M' ? 'Dr' : 'Dre'} {folder.doctor.profile.last_name}
                            </Typography.Text>
                        </div>
                        <Player dictation={dictation} folder={folder} />
                    </div>
                    <div className="flex items-center py-3">
                        <Dropdown
                            menu={{
                                onClick: ({ key }) => {
                                    if (key === "decline") {
                                        decline()
                                    }
                                },
                                items: menu
                            }}
                            trigger={['click']}
                        >
                            <Button
                                type="text"
                                icon={<FontAwesomeIcon icon={regular('ellipsis-vertical')} />}
                            ></Button>
                        </Dropdown>
                    </div>
                </div>}
                







                {!profile.universus && folder.status === 'completed' && <div className="flex-grow h-0 flex items-center w-full justify-center">
                    <img src={reportImg} style={{ height: '100%', margin: 16 }} />
                </div>}

                {!profile.universus && folder.status === 'completed' && <div
                    className={'gap-3 flex items-center border-0 border-t border-solid border-layout px-4 py-3 gap-2'}
                >
                    <Button type="default" className="grow" icon={<FontAwesomeIcon size="lg" icon={solid('hand')} />}>Demander une révision</Button>
                    <Tooltip title={folder.report_path && folder.report_path.split('/').pop().replace('(Draft) ', '')}>
                        <Button onClick={() => downloadFile(folder.report_path)} loading={downloadLoading} type="primary" className="grow" icon={<FontAwesomeIcon size="lg" icon={solid('cloud-arrow-down')} />}>Télécharger le rapport</Button>
                    </Tooltip>
                    {/* {!!archiveEmails.length && <Tooltip title={archiveEmails.join(', ')}><Typography.Text className="cursor-pointer"><FontAwesomeIcon size="lg" icon={solid('envelopes-bulk')} /></Typography.Text></Tooltip>} */}
                    {<Ducore folder={folder} />}
                </div>}











                {profile.universus && <>
                    {((report || transcription) && tabsItems.length > 1) && <Tabs className="tabs-transcription" activeKey={activeTab} items={tabsItems} onChange={setActiveTab} />}
                    <div className="grow">
                        {activeTab === "transcription" && <Correction transcription={transcription} setTranscription={setTranscription} folder={folder} />}
                        {activeTab === "report" && <PDFViewer report={pspdfkit} />}
                    </div>


                    {folder.work.status === 'completed' && folder.report_path && <div
                        className={'gap-3 flex items-center border-0 border-t border-solid border-layout px-4 py-3 gap-2'}
                    >
                        <Tooltip title={folder.report_path && folder.report_path.split('/').pop().replace('(Draft) ', '')}>
                            <Button onClick={() => downloadFile(folder.report_path)} loading={downloadLoading} type="primary" className="grow" icon={<FontAwesomeIcon size="lg" icon={solid('cloud-arrow-down')} />}>Télécharger le rapport</Button>
                        </Tooltip>
                        {!!archiveEmails.length && <Tooltip title={archiveEmails.join(', ')}><Typography.Text className="cursor-pointer"><FontAwesomeIcon size="lg" icon={solid('envelopes-bulk')} /></Typography.Text></Tooltip>}
                        {<Ducore folder={folder} />}
                    </div>}

                    {(ongoing || !transcriber?.generator) && <div
                        className={'gap-3 flex items-center border-0 border-t border-solid border-layout px-4 py-3'}
                    >
                        {!report && <Popover
                            open={gabaritsOpen}
                            onOpenChange={(v) => setGabaritsOpen(v)}
                            content={<div className="flex flex-col gap-3">
                                <Cascader.Panel
                                    autoFocus
                                    open={gabaritsOpen}
                                    options={gabaritsCascader}
                                    onChange={downloadGabarit}
                                    placeholder="Selectionner un gabarit"
                                    showSearch={{
                                        filter: (inputValue, path) =>
                                            path.some(
                                                (option) => (option.label).toLowerCase().indexOf(inputValue.toLowerCase()) > -1,
                                            )
                                    }}
                                    onSearch={(value) => console.log(value)}
                                />
                                {folder.isCyclone && <div className="flex gap-2 items-center">
                                    <FontAwesomeIcon icon={solid('circle-info')} />
                                    Gabarit Cyclone à utiliser
                                </div>}
                            </div>}
                            trigger="click"
                        >
                            <Button type={'default'} loading={loadingGabarits} className="grow" icon={<FontAwesomeIcon size="lg" icon={solid('file-invoice')} />}>Télécharger le gabarit</Button>
                        </Popover>}

                        {(report && (transcriber?.generator || !!folder.work.reviewer)) && <Button loading={loadingGabarits} onClick={() => downloadFile(folder.work.report_path)} type={'default'} className="grow" icon={<FontAwesomeIcon size="lg" icon={solid('file-invoice')} />}>Télécharger le rapport</Button>}

                        {folder.work.translation_path && <Button loading={loadingGabarits} onClick={() => downloadFile(folder.work.translation_path)} type={'default'} className="grow" icon={<FontAwesomeIcon size="lg" icon={solid('language')} />}>Télécharger la traduction</Button>}

                        <input accept=".docx" ref={fileInputReviewRef} id="fileInput" type="file" style={{ display: 'none' }} onChange={(event) => [openChatForReview({
                            finish: () => [setHasSuggest(true), openChatForReview(null)],
                            file: event.target.files[0]
                        })]} />
                        <Button loading={reviewLoading} type={'default'} onClick={() => fileInputReviewRef.current.click()} className="grow" icon={<FontAwesomeIcon size="lg" icon={solid('messages')} />}>Revue Dr Wav</Button>

                        <input accept=".docx" ref={fileInputFinishRef} id="fileInput" type="file" style={{ display: 'none' }} onChange={finish} />
                        <Tooltip className="grow" title={transcriber?.review_wav && !hasSuggest ? "Demander une revue du dossier pour pouvoir envoyer le rapport." : ""}>
                            {(transcriber?.review || folder.work.service === 'translate') && <Button block onClick={() => fileInputFinishRef.current.click()} type={'primary'} disabled={transcriber?.review_wav && !hasSuggest} loading={finishLoading} className="grow" icon={<FontAwesomeIcon size="lg" icon={solid('paper-plane')} />}>Envoyer le rapport</Button>}
                            {!transcriber?.review && folder.work.service !== 'translate' && <Dropdown.Button style={{ width: '100%' }} block menu={{
                                items: [{
                                    label: 'Demander une correction',
                                    key: 'review',
                                    disabled: finishLoading,
                                    icon: <FontAwesomeIcon size="lg" icon={solid('magnifying-glass')} />,
                                }], onClick: (a) => [setAskReview(true), fileInputFinishRef.current.click()]
                            }} disabled={transcriber?.review_wav && !hasSuggest} onClick={() => fileInputFinishRef.current.click()} buttonsRender={([leftButton, rightButton]) => [
                                cloneElement(leftButton, { block: true, icon: <FontAwesomeIcon size="lg" icon={solid('paper-plane')} />, loading: finishLoading }),
                                cloneElement(rightButton, {}),
                            ]}>Envoyer le rapport</Dropdown.Button>}
                        </Tooltip>
                    </div>}

                    {folder.work.status === 'ready' && <>
                        <div
                            className={'gap-3 flex items-center border-0 border-t border-solid border-layout px-4 py-3'}
                        >
                            <Tooltip title={folder.work.report_path && folder.work.report_path.split('/').pop().replace('(Draft) ', '')}>
                                <Button className="grow" onClick={() => downloadFile(folder.work.report_path)} loading={downloadLoading} className="grow" icon={<FontAwesomeIcon size="lg" icon={solid('file-arrow-down')} />}>Télécharger le rapport</Button>
                            </Tooltip>
                            <Button onClick={() => {
                                setArchiveModal(true)
                                if (reportIsCorrect) {
                                    archive()
                                } else {

                                }
                            }} type="primary" className="grow" icon={<FontAwesomeIcon fixedWidth size="lg" icon={solid('paper-plane')} />}>Envoyer le rapport</Button>
                        </div>

                        <Modal centered zIndex={100000} closeIcon={false} title={"Rapport"} okButtonProps={{ disabled: !reportIsCorrect && !fileArchive, loading: archiveLoading }} open={archiveModal} onOk={() => archive()} okText={"Envoyer"} cancelText={t('cancel')} onCancel={() => setArchiveModal(false)}>
                            <div className="flex flex-col gap-3">
                                <Label label={"Facturation - unités"} icon={light('file-invoice-dollar')}>
                                    <Input type="number" value={invoiceUnit} onChange={(e) => setInvoiceUnit(e.target.value)} />
                                </Label>

                                <Label label={t('folders.fields.comments')} icon={light('memo-circle-info')}>
                                    <Textarea value={archiveComment} onChange={(e) => setArchiveComment(e.target.value)} />
                                </Label>


                                <Checkbox checked={reportIsCorrect} onChange={(e) => setReportIsCorrect(e.target.checked)} size="large">
                                    Le rapport soumis par la transcriptrice est correct
                                </Checkbox>
                                <input accept=".docx" ref={fileInputRef} id="fileInput" type="file" style={{ display: 'none' }} onChange={handleFileChange} />
                                {!reportIsCorrect && <Button type="text" onClick={() => fileInputRef.current.click()} icon={<FontAwesomeIcon fixedWidth size="lg" icon={solid('file-arrow-up')} />}>Envoyer le rapport {fileArchive && '(1)'}</Button>}
                                {!!archiveEmails.length && <Typography.Text className="flex items-center gap-2 font-bold">
                                    <FontAwesomeIcon icon={solid('envelopes-bulk')} />
                                    {archiveEmails.join(', ')}
                                    {<span className="ml-auto"><Ducore folder={folder} /></span>}
                                </Typography.Text>}
                            </div>
                        </Modal>
                    </>}

                </>}
            </>}
        </>
    )
}

function Correction({ folder, transcription, setTranscription }) {
    const handleChange = (event) => {
        const newText = event.target.value;
        setTranscription(newText);
    };

    return <Textarea
        autoFocus
        style={{ height: '100%', padding: '24px 42px', fontSize: '15px', resize: 'none' }}
        onChange={handleChange}
        value={transcription}
        variant="borderless"
        className="h-full scroll resize-none"
    />
}

function transformGabarits(data) {
    const result = [];
    const groupMap = new Map();

    data.forEach(item => {
        const groupParts = item.group.split('/').filter(part => part !== 'work' && part !== 'gabarits');
        let currentLevel = result;
        let currentPath = '';

        groupParts.forEach((part, index) => {
            currentPath += (index > 0 ? '/' : '') + part;
            if (!groupMap.has(currentPath)) {
                const newGroup = {
                    value: part,
                    label: part,
                    children: []
                };
                currentLevel.push(newGroup);
                groupMap.set(currentPath, newGroup);
            }
            currentLevel = groupMap.get(currentPath).children;
        });

        currentLevel.push({
            value: item.gabarit.name,
            label: item.gabarit.name
        });
    });

    // Remove unnecessary 'children' property for leaf nodes
    const removeEmptyChildren = (arr) => {
        arr.forEach(item => {
            if (item.children && item.children.length === 0) {
                delete item.children;
            } else if (item.children) {
                removeEmptyChildren(item.children);
            }
        });
    };

    removeEmptyChildren(result);

    return result;
}

function Ducore({ folder }) {
    if (folder.company.id !== 2) {
        return <></>
    }

    return <a style={{ position: 'relative', top: 3 }} target="_blank" rel="noreferrer" href={`https://ducore.illuxi.com/expertises/view/${folder.reference}`}><img src={ducore} style={{ width: 24 }} /></a>
}

function PreviewTranscription() {
    return <div className="flex flex-col items-center h-full justify-center flex-grow">
        <Button type="primary" style={{ transform: 'scale(3.5)', marginBottom: 64 }} size="large" shape="circle" icon={<FontAwesomeIcon icon={duotone("microphone")} />} />
        <img style={{ height: 100, marginBottom: 42 }} src={voice} />
        {/* <Tag>16:23</Tag> */}
        {/* <div className="flex items-center gap-2 mt-2">
            <Button>Terminer la dictée</Button>
        </div> */}

        <div className="flex items-center gap-4 mt-6">
            <Button shape="round" type="primary" size="large" icon={<FontAwesomeIcon icon={solid('paper-plane')} />}>Envoyer à Universus</Button>
        </div>
    </div>
}