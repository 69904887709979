import { useMemo } from 'react'
import { DateTime } from 'luxon'

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Badge, theme, Typography } from 'antd'
import { useTranslation } from 'react-i18next'

const { useToken } = theme

const { Text } = Typography

export default function CalendarHeader({ filterDate, setFilterDate, viewType }) {
  const { t } = useTranslation()
  const { token } = useToken()

  const isToday = useMemo(() => filterDate.hasSame(DateTime.now(), 'day'), [filterDate])

  const next = () => setFilterDate(filterDate.plus({ [viewType]: 1 }))
  const prev = () => setFilterDate(filterDate.minus({ [viewType]: 1 }))

  return (
    <div className="flex items-center gap-1.5">
      <Button type={isToday ? 'dashed' : 'default'} onClick={() => setFilterDate(DateTime.now())}>
        {t('planning.today')}
      </Button>
      <div>
        <Button onClick={prev} type="text" icon={<FontAwesomeIcon icon={regular('chevron-left')} />} shape="round" />
        <Button onClick={next} type="text" icon={<FontAwesomeIcon icon={regular('chevron-right')} />} shape="round" />
      </div>
      {(viewType === 'week' || viewType === 'scheduler') && (
        <Text strong className="text-lg first-letter:uppercase">
          {filterDate.toFormat('LLLL, y')}
        </Text>
      )}
      {viewType === 'day' && (
        <Text strong className="text-lg first-letter:uppercase">
          {filterDate.toFormat('DDDD')}
        </Text>
      )}
      {viewType === 'month' && (
        <Text strong className="text-lg first-letter:uppercase">
          {filterDate.toFormat('LLLL y')}
        </Text>
      )}
      <Badge
        className="ml-2"
        style={{
          backgroundColor: token.colorFillSecondary,
          color: token.colorTextTertiary,
        }}
        count={t('planning.week') + ' ' + filterDate.toFormat('n')}
      ></Badge>
    </div>
  )
}
