import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { omit } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useAuth } from 'src/auth/Auth'

import { useUI } from 'src/layout/UI'

import { supabase } from 'src/supabase'

export const useProfiles = ({ section = '*', search = '', sorts = [], filters = [] } = {}) => {
  const { session } = useAuth()

  return useQuery({
    queryKey: ['profiles', section],
    initialData: [],
    queryFn: async () => {
      return []
      const query = supabase.from('profiles').select('*').is('deleted_at', null)

      if (search.trim().length) {
        query.textSearch('first_name', search.trim() + ':*')
      }

      if (!sorts.length) {
        query.order('id', { ascending: false })
      } else {
        sorts.forEach((sort) => {
          query.order(sort.field, { ascending: sort.ascending })
        })
      }

      const { data } = await query

      return data
    }
  })
}

export const useDoctors = ({ section = '*', search = '', sorts = [], filters = [], or } = {}) => {
  const { data: profile } = useProfile()

  return useQuery({
    queryKey: ['doctors'],
    initialData: [],
    queryFn: async () => {
      const query = supabase.from('doctors').select('id, specialities, company_id, profile:profiles(gender, id, first_name, last_name, avatar)').order('profile(last_name)', { ascending: true }).is('deleted_at', null)

      if (profile.company) {
        query.eq('company_id', profile.company.id)
      }

      filters.forEach((filter) => {
        query.order(filter.field, filter.value)
      })

      if (or) {
        query.or(or)
      }

      const { data } = await query

      return data
    }
  })
}

export const useTranscribers = ({ section = '*', search = '', sorts = [], filters = [], or } = {}) => {
  const { data: profile } = useProfile()

  return useQuery({
    queryKey: ['transcribers'],
    initialData: [],
    queryFn: async () => {
      const query = supabase.from('transcribers').select('id, profile:profiles(id, first_name, last_name, avatar)').order('profile(last_name)', { ascending: true })

      const { data } = await query

      return data
    }
  })
}

export const useCompanies = ({ section = '*', search = '', sorts = [], filters = [] } = {}) => {
  return useQuery({
    queryKey: ['companies'],
    initialData: [],
    queryFn: async () => {
      const query = supabase.from('companies').select('*').is('deleted_at', null).order('name', { ascending: true })

      const { data } = await query

      return data
    }
  })
}

export const useSpecialities = ({ section = '*', search = '', sorts = [], filters = [] } = {}) => {
  return useQuery({
    queryKey: ['specialities'],
    initialData: [],
    queryFn: async () => {
      const query = supabase.from('specialities').select('*').order('name', { ascending: true })

      const { data } = await query

      return data
    }
  })
}

export const useProfile = () => {
  const { session } = useAuth()
  const profile = localStorage.getItem('profile')

  return useQuery({
    queryKey: ['profile'],
    initialData: profile ? JSON.parse(profile) : null,
    queryFn: async () => {
      const { data } = await supabase
        .from('profiles')
        .select('id, email, first_name, last_name, avatar, company:companies(id, name, logo), universus, gender, role')
        .eq('id', session.user.user_metadata.profile_id)
        .single()

      if (!data.universus) {
        localStorage.setItem('logo', data.company.logo || data.company.name)
      }

      const profileData = {
        ...data,
        isPreview: ['doctor@ducore.one', 'administrator@ducore.one'].includes(data.email)
      }

      localStorage.setItem('profile', JSON.stringify(profileData))

      return profileData
    },
    refetchOnMount: false,
  })
}

export const useTranscriber = () => {
  const { session } = useAuth()

  return useQuery({
    queryKey: ['transcriber'],
    initialData: null,
    queryFn: async () => {
      if (session.user.user_metadata.transcriber_id) {
        const { data } = await supabase
          .from('transcribers')
          .select('*')
          .eq('id', session.user.user_metadata.transcriber_id)
          .single()

        const { data: current } = await supabase
          .from('folders')
          .select('id, pydio_id, work:works(status, reviewer_id, transcriber_id)')
          .eq('work.status', 'ongoing')
          .or(`and(transcriber_id.eq.${data.id}, reviewer_id.is.null), reviewer_id.eq.${data.id}`, { referencedTable: 'works' })
          .not('work', 'is', null)
          .is('deleted_at', null)

        return {
          ...data,
          current: current[0]
        }
      } else {
        return false
      }
    },
    refetchOnMount: false,
  })
}

export const useUpdateProfile = () => {
  const queryClient = useQueryClient()
  const { messageApi } = useUI()
  const { session } = useAuth()
  const { t } = useTranslation()


  return useMutation({
    mutationFn: async (values) => {
      const { error, data } = await supabase
        .from('profiles')
        .update(omit(values, ['id']))
        .eq('id', values.id)

      if (error) {
        throw new Error(error.message)
      }

      return data
    },
    onSuccess: (result, values) => {
      const profiles = queryClient.getQueryData(['profiles', '*'])

      if (profiles) {
        queryClient.setQueryData(
          ['profiles', '*'],
          profiles.map((item) => (item.id === values.id ? { ...item, ...values } : item))
        )
      }

      if (values.id === session.user.user_metadata.profile_id) {
        queryClient.setQueryData(['profile'], (old) => ({ ...old, ...values }))
      }
    },
    onError: (error, values, context) => {
      let message = error.message

      if (message.includes('duplicate')) {
        message = t('email-exists')
      }

      messageApi.open({
        type: 'error',
        content: message,
        duration: 4,
      })
    },
  })
}

export const useInsertProfile = () => {
  const queryClient = useQueryClient()
  const { messageApi } = useUI()

  return useMutation({
    mutationFn: async (values) => {
      const { data, error } = await supabase.functions.invoke('invite-user', {
        body: values,
      })

      //@TODO: supabase.functions.invoke throwing error from sdk not server
      if (error || data.status !== 200) {
        throw new Error(error?.message || data?.message)
      }

      return data
    },
    onMutate: (values) => { },
    onSuccess: (result, values, context) => {
      queryClient.invalidateQueries({ queryKey: ['profiles', '*'] })
      queryClient.invalidateQueries({ queryKey: ['profiles', 'teammates'] })
    },
    onError: (error, values, context) => {
      messageApi.open({
        type: 'error',
        content: error.message,
        duration: 4,
      })
    },
  })
}

export const useRemoveProfile = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (values) => {
      const { data, error } = await supabase.functions.invoke('ban-user', {
        body: values,
      })
    },
    onMutate: (values) => {
      queryClient.setQueryData(['profiles', '*'], (old) => old.filter((item) => item.id !== values.id))
    },
    onSuccess: (result, values, context) => { },
    onError: (error, values, context) => { },
  })
}
