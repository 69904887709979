import { useFolders, useFoldersCounts, useInsertFolder, useUpdateFolder, useRemoveFolder } from 'src/folders/FoldersApi'

import FoldersColumns from 'src/folders/FoldersColumns'
import FoldersForm, { FoldersFormInitialValues } from 'src/folders/FoldersForm'

import Page from 'src/@components/page/Page'
import { duotone, light, regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useProfile } from 'src/profiles/ProfilesApi'
import { useEffect } from 'react'
import { supabase } from 'src/supabase'
import { useAuth } from 'src/auth/Auth'
import { useSettings } from 'src/settings/SettingsApi'

export default function FoldersPage() {
  const { data: profile } = useProfile()
  const { data: settings } = useSettings()

  if (!profile || !settings.permissions) {
    return null
  }

  return (
    <>
      <Page
        {...{
          translateKey: 'folders',
          columns: FoldersColumns,
          sections: FoldersSections.filter(section => section.filter(profile, settings)),

          FormComponent: FoldersForm,
          FormInitialValues: FoldersFormInitialValues,

          syncRequests: true,

          useCounts: useFoldersCounts,
          useQuery: useFolders,
          useInsert: useInsertFolder,
          useRemove: useRemoveFolder,
          useUpdate: useUpdateFolder,
          icons: {
            solid: solid('folder-open'),
            regular: regular('folder-open'),
            light: light('folder-open')
          }
        }}
      />
    </>
  )
}


export const FoldersSections = [
  // {
  //   value: '*',
  //   path: '/all',
  //   icon: duotone('folder-open')
  // },
  {
    value: 'upcoming',
    path: '/upcoming',
    showCount: true,
    filter: (profile, settings) => !profile.universus,
    icon: duotone('folders')
  },
  {
    value: 'available',
    path: '/available',
    showCount: true,
    suppressInsertRow: true,
    filter: (profile, settings) => profile.role === "transcriber",
    icon: duotone('folders')
  },
  {
    value: 'inprogress',
    path: '/inprogress',
    showCount: true,
    filter: (profile, settings) => !profile.universus || (profile.role === "transcriber" && settings.permissions && settings.permissions["read-folders"]),
    icon: duotone('folder-open')
  },
  {
    value: 'validation',
    path: '/validation',
    showCount: true,
    suppressInsertRow: true,
    filter: (profile, settings) => (profile.role === "transcriber" && settings.permissions && settings.permissions["read-folders"]),
    icon: duotone('envelopes-bulk')
  },
  // {
  //   value: 'reviewing',
  //   path: '/reviewing',
  //   showCount: true,
  //   icon: duotone('folder-magnifying-glass'),
  //   suppressInsertRow: true
  // },
  // {
  //   value: 'available',
  //   path: '/available',
  //   universus: true,
  //   icon: duotone('folder-grid'),
  //   suppressInsertRow: true
  // },
  // {
  //   value: 'tocorrect',
  //   path: '/tocorrect',
  //   universus: true,
  //   icon: duotone('calendar-lines-pen'),
  //   suppressInsertRow: true
  // },
  // {
  //   value: 'tovalidate',
  //   path: '/tovalidate',
  //   universus: true,
  //   icon: duotone('file-check'),
  //   suppressInsertRow: true
  // },
  // {
  //   value: 'totranslate',
  //   path: '/totranslate',
  //   universus: true,
  //   icon: duotone('language'),
  //   suppressInsertRow: true
  // },
  {
    value: 'completed',
    path: '/completed',
    icon: duotone('folder-check'),
    filter: () => true,
    suppressInsertRow: true
  }
]