import { light, solid } from "@fortawesome/fontawesome-svg-core/import.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, Input, Typography } from "antd"
import { useEffect, useMemo, useRef, useState } from "react"
import { supabase } from "src/supabase"
import { useProfile } from "src/profiles/ProfilesApi"
import { useChat } from 'ai/react'
import diff_match_patch from "diff-match-patch"
import Avatar from "src/@components/Avatar"
import { useAuth } from "src/auth/Auth"

const { APP_CHAT_URL } = import.meta.env

export default function Chat({ setReviewLoading, reviewLoading, transcription, folder, review }) {
    const [initialMessages, setInitialMessages] = useState(null)

    useEffect(() => {
        const getInitialMessages = async () => {
            const { data, error } = await supabase
                .from('chats')
                .select('*, profile:profiles(avatar, first_name, last_name)')
                .eq('work_id', folder.work_id)
                .order('created_at', { ascending: true })

            setTimeout(() => {
                setInitialMessages(data)
            }, 500)
        }

        getInitialMessages()
    }, [])

    if (!initialMessages) {
        return <div className="relative h-full w-full">
            {<div className="absolute top-0 left-0 flex flex-col h-full grow gap-4 z-20 items-center justify-center w-full">
                <Typography.Text type="secondary" className="relative">
                    <FontAwesomeIcon icon={solid('chart-radar')} size={"5x"} />
                    <FontAwesomeIcon
                        style={{ right: -24, bottom: 0 }}
                        className="fa-spinner text-primary absolute opacity-60"
                        icon={solid('spinner')}
                        size="lg"
                    />
                </Typography.Text>
                <Typography.Text type="secondary">
                    <strong>Chargement de la conversation...</strong>
                </Typography.Text>
            </div>}
        </div>
    } else {
        return <Messages setReviewLoading={setReviewLoading} reviewLoading={reviewLoading} review={review} initialMessages={initialMessages} folder={folder} transcription={transcription} />
    }
}

function Messages({ setReviewLoading, reviewLoading, review, initialMessages, folder, transcription }) {
    const [autoScroll, setAutoScroll] = useState(true)
    const { session } = useAuth()
    const { data: profile } = useProfile()

    const { messages, input, handleInputChange, handleSubmit, isLoading, setMessages, error, reload } = useChat({
        api: `${APP_CHAT_URL}/chat`,
        keepLastMessageOnError: true,
        initialMessages: [
            ...initialMessages
        ],
        headers: {
            Authorization: `Bearer ${session.access_token}`,
        },
        body: {
            work_id: folder.work_id,
        },
    })

    useEffect(() => {
        if (review && !reviewLoading) {
            setReviewLoading(true)
            setMessages((messages) => [...messages, {
                role: 'system',
                content: `${review.file.name} en cours d'analyse`,
                analysing: true
            }])

            const formData = new FormData()
            formData.append(`report`, review.file, review.file.name)
            formData.append(`work_id`, folder.work.id)
            formData.append(`pydio_id`, folder.pydio_id)

            fetch(`${APP_CHAT_URL}/review`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${session.access_token}`,
                },
                body: formData,
            }).then(async res => {
                const data = await res.json()

                setMessages((messages) => messages.map(message => message.analysing ? {
                    role: 'system',
                    review: true,
                    content: data
                } : message))

                review.finish()
            })
        }
    }, [review])

    useEffect(() => setMessages((messages) => {
        const _messages = messages[0]?.root ? messages.slice(1) : messages
        return [getSystem(transcription, folder.jsonMandate), ..._messages]
    }), [folder, transcription])

    const messagesEndRef = useRef(null)
    const messagesRef = useRef(null)

    const scrollBottom = () => messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })

    useEffect(() => {
        if (autoScroll) {
            scrollBottom()
        }
    }, [messages, autoScroll])

    useEffect(() => {
        if (error) {
            scrollBottom()
        }
    }, [error])

    useEffect(() => {
        const handleScroll = () => {
            if (!messagesRef.current) return

            const isAtBottom = messagesRef.current.scrollHeight - messagesRef.current.scrollTop <= messagesRef.current.clientHeight + 16;

            if (isAtBottom) {
                setAutoScroll(true)
            } else {
                setAutoScroll(false)
            }
        }

        messagesRef.current.addEventListener('scroll', handleScroll)
    }, [])

    const startContent = profile.universus ? `Bonjour,\nje suis Dr Wav, ton assistant de transcription !\nJe suis là pour t'aider avec les dossiers médicaux en cours.\nTu peux me poser des questions sur les termes médicaux, les détails des dossiers, ou tout autre sujet lié à ton travail.\nN'hésite pas à me demander de l'aide !` : `Bonjour !\nJe suis votre assistant médical virtuel,\nici pour vous aider dans la gestion de vos examens et expertises médicales.\nJe peux vous assister dans la préparation des comptes rendus, la génération de documents administratifs, ou encore vous rappeler des protocoles d'examen.\nN'hésitez pas à me solliciter pour toute tâche, je suis là pour simplifier votre quotidien.` 

    return <div style={{ height: "100%", position: "relative", display: 'flex', flexDirection: 'column', gap: 8, justifyContent: 'flex-start' }}>
        <div className="flex relative flex-col gap-1.5 px-4 flex-grow">
            <div ref={messagesRef} className="scroll-smooth px-4 py-2 box-border absolute top-0 left-0 scroll overflow-auto h-full w-full">
                <Message message={{
                    hide_feedback: true,
                    role: 'system',
                    content: startContent
                }} />
                {messages.filter(message => !message.root).map((message, index) => <Message key={index} message={message} />)}

                {error && <div className="flex flex-col gap-2 items-center mt-2">
                    <Typography.Text type="secondary">Une erreur est survenue</Typography.Text>
                    <Button onClick={reload} type="text" icon={<FontAwesomeIcon icon={solid('arrow-rotate-right')} />}>Réessayer</Button>
                </div>}
                <div ref={messagesEndRef} />
            </div>
            {!autoScroll && <Button onClick={scrollBottom} shape={'circle'} icon={<FontAwesomeIcon icon={solid('arrow-down')} />} style={{ position: 'absolute', bottom: "16px", left: "50%", transform: "translateX(-50%)" }} />}
        </div>

        <div className="p-4 border-0 border-t border-solid border-layout">
            <form onSubmit={handleSubmit}>
                <div className="bg-layout width-f h-10 flex items-center px-4 py-2 rounded-lg overflow-hidden">
                    <Input disabled={isLoading || reviewLoading} value={input} onChange={handleInputChange} autoFocus variant="borderless" placeholder="Ecrire un message" />
                    <Button disabled={isLoading || reviewLoading} type="text" shape="circle" size="large" icon={<FontAwesomeIcon icon={light("paperclip")} />} />
                    {/* <Tooltip placement="right" arrow={false} title={<span style={{ fontWeight: 500, fontSize: 11 }}>CTRL K</span>}> */}
                    <Button disabled={isLoading || reviewLoading} type="text" shape="circle" size="large" icon={<FontAwesomeIcon icon={light("microphone")} />} />
                    {/* </Tooltip> */}
                    <div style={{ transition: 'ease-in 0.1s all' }}>
                        {<Button loading={isLoading || reviewLoading} type="text" shape="circle" size="large" icon={<FontAwesomeIcon icon={light("paper-plane-top")} />} />}
                    </div>
                </div>
            </form>
        </div>
    </div>
}

function Message({ message }) {
    const { data: profile } = useProfile()

    return <div key={message.id} className="p-4 py-3 flex items-start flex-col">
        <div className="flex items-start" style={{ gap: 26 }}>
            <div>
                {message.role === 'user' && <Avatar size={38} profile={message.profile || profile} />}
                {message.role !== 'user' && <Avatar size={38} universus />}
            </div>
            <Typography.Text type={message.analysing ? 'secondary' : 'default'} strong={message.analysing} className="whitespace-break-spaces" style={{ paddingTop: 7 }}>
                {message.review && displaySuggestions(message.content)}
                {!message.review && message.content}
                {message.analysing && <>
                    <span class="dot1">.</span>
                    <span class="dot2">.</span>
                    <span class="dot3">.</span>
                </>}
            </Typography.Text >
        </div >
        {/* {message.role !== 'user' && !message.hide_feedback && <div className="flex items-end" style={{ paddingLeft: 48 }}>
            <Button type="text" shape="circle" size="large" icon={<FontAwesomeIcon icon={light("thumbs-up")} />} />
            <Button type="text" shape="circle" size="large" icon={<FontAwesomeIcon icon={light("thumbs-down")} />} />
        </div>} */}
    </div >
}

const getSystem = (transcription, mandate) => ({
    root: true,
    role: 'system',
    content: `
    You are an assistant designed to help medical transcriptionists. You have access to the transcription of the exam and the mandate. You can answer questions about medical terms, perform grammar checks, and provide assistance with any other inquiries related to the transcription or the mandate.

    Mandate: ${JSON.stringify(mandate)}

    Transcription: ${transcription}

    Task: Please assist with the following request from the transcriptionist.
    `
})

function displaySuggestions(content) {
    const suggestions = JSON.parse(content)
    return [
        suggestions?.gallicism?.length && <div style={{ marginBottom: 10 }}><strong style={{ fontSize: 14 }}>Gallicismes</strong> <ul style={{ marginTop: 10 }}>{suggestions.gallicism.map(({ level, ...suggestion }) => <li>{displaySuggestion(suggestion)} {displaySuggestionLevel(level)}</li>)}</ul></div>,
        suggestions?.anglicism?.length && <div style={{ marginBottom: 10 }}><strong style={{ fontSize: 14 }}>Anglicismes</strong> <ul style={{ marginTop: 10 }}>{suggestions.anglicism.map(({ level, ...suggestion }) => <li>{displaySuggestion(suggestion)} {displaySuggestionLevel(level)}</li>)}</ul></div>,
        suggestions?.coherence?.length && <div style={{ marginBottom: 10 }}><strong style={{ fontSize: 14 }}>Incohérences</strong> <ul style={{ marginTop: 10 }}>{suggestions.coherence.map(({ level, ...suggestion }) => <li>{displaySuggestion(suggestion)} {displaySuggestionLevel(level)}</li>)}</ul></div>,
        suggestions?.grammar?.length && <div style={{ marginBottom: 10 }}><strong style={{ fontSize: 14 }}>Grammaire</strong> <ul style={{ marginTop: 10 }}>{suggestions.grammar.map(({ level, ...suggestion }) => <li>{displaySuggestion(suggestion)} {displaySuggestionLevel(level)}</li>)}</ul></div>,
        suggestions?.spelling?.length && <div style={{ marginBottom: 10 }}><strong style={{ fontSize: 14 }}>Orthographe</strong> <ul style={{ marginTop: 10 }}>{suggestions.spelling.map(({ level, ...suggestion }) => <li>{displaySuggestion(suggestion)} {displaySuggestionLevel(level)}</li>)}</ul></div>,
    ].filter(s => !!s)
}

function displaySuggestion({ original, suggestion }) {
    if (!original) return suggestion;

    const dmp = new diff_match_patch();
    const diffResult = dmp.diff_main(original, suggestion);

    dmp.diff_cleanupSemantic(diffResult);

    return diffResult.map(([change, content]) => {
        if (change === 1) return <strong>{content}</strong>;
        if (change === -1) return <span style={{ textDecoration: 'underline' }}>{content}</span>;
        return content;
    });
}

function displaySuggestionLevel(level) {
    if (level === 'medium') return '🟡';
    if (level === 'high') return '🔴';
    return '🟢';
}