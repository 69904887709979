import { useFolders, useFoldersCounts, useInsertFolder, useUpdateFolder, useRemoveFolder } from 'src/folders/FoldersApi'

import FoldersColumns from 'src/folders/FoldersColumns'
import FoldersForm, { FoldersFormInitialValues } from 'src/folders/FoldersForm'

import Page from 'src/@components/page/Page'
import { duotone, light, regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useProfile } from 'src/profiles/ProfilesApi'
import { useEffect } from 'react'
import { supabase } from 'src/supabase'
import { useAuth } from 'src/auth/Auth'
import { useSettings } from 'src/settings/SettingsApi'
import Calendar from 'src/calendar/calendar/Calendar'


export default function CalendarPage() {
  const { data: profile } = useProfile()
  const { data: settings } = useSettings()

  if (!profile || !settings.permissions) {
    return null
  }

  return (
    <>
      <Page
        {...{
          translateKey: 'folders',
          columns: FoldersColumns,
          sections: PlanningSections,

          ListComponent: Calendar,

          FormComponent: FoldersForm,
          FormInitialValues: FoldersFormInitialValues,

          syncRequests: true,

          useCounts: useFoldersCounts,
          useQuery: useFolders,
          useInsert: useInsertFolder,
          useRemove: useRemoveFolder,
          useUpdate: useUpdateFolder,

          icons: {
            solid: solid('inbox'),
            regular: regular('inbox'),
            light: light('inbox'),
          },
        }}
      />
    </>
  )
}

export const PlanningSections = [
  {
    value: 'day',
    path: '/calendar/day',
    color: 'primary',
    showCount: true,
    icon: duotone('sun')
  },
  {
    value: 'week',
    path: '/calendar/week',
    color: 'primary',
    showCount: true,
    icon: duotone('columns-3')
  },
  {
    value: 'month',
    path: '/calendar/month',
    color: 'primary',
    showCount: true,
    icon: duotone('table-cells')
  }
]
