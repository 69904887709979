import { useEffect, useMemo, useState } from 'react'
import { DateTime } from 'luxon'

import { useUI } from 'src/layout/UI'

import './Calendar.css'
import '@mobiscroll/react/dist/css/mobiscroll.min.css'
import { Datepicker, locale } from '@mobiscroll/react'

import clsx from 'clsx'
import CalendarDays from './CalendarDays'
import CalendarMonth from './CalendarMonth'
// import Scheduler from './Scheduler'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Checkbox, Input, Typography } from 'antd'
import { useDoctors, useProfiles } from 'src/profiles/ProfilesApi'
import { useAuth } from 'src/auth/Auth'
import { useSettings } from 'src/settings/SettingsApi'
import { useTranslation } from 'react-i18next'
import Avatar from 'src/@components/Avatar'

const { Text } = Typography

export default function Calendar({ openForm, isFormOpen, section, filterDate, setFilterDate, data }) {
  const { t } = useTranslation()
  const { data: settings } = useSettings()
  const { data: doctors } = useDoctors()
  const { session } = useAuth()
  const { darkMode } = useUI()

  const View = useMemo(() => {
    switch (section.value) {
      case 'day':
        return CalendarDays

      case 'week':
        return CalendarDays

      case 'month':
        return CalendarMonth

      // case 'scheduler':
      //   return Scheduler
    }
  }, [section])

  const events = useMemo(() => {
    return data.map(({ assessment_date, patient, ...payload }) => {
      const randomHour = Math.floor(Math.random() * (17 - 9)) + 9;
      const start = DateTime.fromISO(assessment_date).set({ hour: randomHour, minute: 0, second: 0, millisecond: 0 });
      const end = start.plus({ hours: 1 });
  
      return {
        start: start.toISO(),
        end: end.toISO(),
        title: patient,
        payload
      };
    });
  }, [data]);

  return (
    <div className="flex h-full grow">
      <div className="flex w-80 flex-col border-0 border-r border-solid border-layout" style={{ ['borderRightWidth']: 1 }}>
        <div className="h-80">
          <Datepicker
            locale={locale[settings.lang]}
            onChange={({ value }) => setFilterDate(DateTime.fromJSDate(value))}
            themeVariant={darkMode ? 'dark' : 'light'}
            theme="material"
            controls={['calendar']}
            display="inline"
            value={filterDate}
            touchUi={'auto'}
          />
        </div>
        {!session.user.user_metadata.doctor_id && <div className="p-4 flex flex-col flex-grow h-0 gap-4">
          <Input
            prefix={<FontAwesomeIcon className="mr-1" icon={regular('magnifying-glass')} />}
            allowClear
            variant="filled"
            placeholder={t('planning.search-teammates')}
          />

          <div className="flex grow flex-col gap-2 p-3 scroll overflow-y-scroll">
            {doctors.map(({ profile }) => (
              <div key={profile.id} className="flex cursor-pointer items-center gap-3">
                <Checkbox defaultChecked={true} />
                <Avatar profile={profile} />
                <Text>
                  {profile.first_name} {profile.last_name}
                </Text>
              </div>
            ))}
          </div>
        </div>}
      </div>
      <div className="relative grow">
        <div className={clsx('absolute left-0 top-0 h-full w-full', isFormOpen && 'formOpen')}>
          <View events={events} doctors={doctors} section={section} filterDate={filterDate} setFilterDate={setFilterDate} openForm={openForm} />
          <div className="calendar-scroll-overlay" />
        </div>
      </div>
    </div>
  )
}
