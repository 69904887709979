import Label from 'src/@components/Label'
import { light, regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Button, Popconfirm, Collapse, Spin, DatePicker, Form, Radio, Switch, Tag, Select as SelectAnt, Tooltip, Typography } from 'antd'
import Input from 'src/@components/form/Input'
import Select from 'src/@components/form/Select'
import Textarea from 'src/@components/form/Textarea'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useMemo, useState } from 'react'
import { useCompanies, useDoctors, useProfile, useSpecialities } from 'src/profiles/ProfilesApi'
import Dragger from 'antd/es/upload/Dragger'
import { useAuth } from 'src/auth/Auth'
import Avatar from 'src/@components/Avatar'
import dayjs from 'dayjs'
import { supabase } from 'src/supabase'
import FormActions from 'src/@components/form/FormActions'
import { PageContext } from 'src/@components/page/Page'
import { useLocation, useParams, useNavigate } from 'react-router-dom'
import { useUI } from 'src/layout/UI'

export const getDoctorLabel = (profile) => `${profile.gender === 'M' ? 'Dr' : 'Dre'} ${profile.first_name} ${profile.last_name}`

export const FoldersFormInitialValues = ({ profile, values }) => {
  if (values) {
    return {
      id: values.id,
      folders: [{
        ...values,
        doctor_id: !values.doctor ? [] : [getDoctorLabel(values.doctor.profile)],
        assessment_date: values.assessment_date && dayjs(values.assessment_date)
      }],
      company_id: values.company?.id
    }
  }

  return ({
    folders: [{}],
    company_id: profile.company?.id
  })
}

export default function FoldersForm({ readOnly, disabled, form, isUpdate, values, isMutationLoading, onClose, isInline, update, remove }) {
  const { t } = useTranslation()
  const company_id = Form.useWatch('company_id', form)
  const folders = Form.useWatch('folders', form)

  const { data: profile } = useProfile()
  const { data: companies } = useCompanies()
  const { data: specialities } = useSpecialities()
  const { session } = useAuth()

  const { data: remoteDoctors } = useDoctors()

  const [doctors, setDoctors] = useState([])
  const [activeKey, setActiveKey] = useState({})
  const [filesLoading, setFilesLoading] = useState(false)
  const [downloadLoading, setDownloadLoading] = useState(false)

  const isCompleted = isUpdate && (values.status === 'completed' || (values.folders && values.folders[0].status === 'completed'))
  const report_path_obj = values.report_path || (values.folders && values.folders[0].report_path)
  const report_path = isCompleted && report_path_obj && report_path_obj.split('/')[report_path_obj.split('/').length - 1]

  useEffect(() => {
    if (!isInline && folders && values.id && !folders[0].files) {
      setFilesLoading(true)
      supabase.storage.from('folders').list(`${values.company.id}/${values.folder_id}`)
        .then(({ data, error }) => {
          form.setFieldValue('folders', folders.map((folder, index) => {
            return {
              ...folder,
              files: data.filter(file => file.name !== report_path).map(file => ({
                name: file.name,
                uid: file.id,
                id: file.id
              }))
            }
          }))

          setFilesLoading(false)
        })
        .catch(() => setFilesLoading(false))
    }
  }, [values.id, folders])

  useEffect(() => {
    if (remoteDoctors && company_id) {
      setDoctors(
        remoteDoctors
          .filter(doctor => {
            return profile.universus ? company_id === doctor.company_id : true
          })
          .map((item) => ({ label: getDoctorLabel(item.profile), value: String(item.id), doctor: item }))
      )
    }
  }, [remoteDoctors, company_id])

  const addFolder = (add) => {
    add({ type: 'transcribe', doctor_id: folders[folders.length - 1].doctor_id })
  }

  let assessment_dateTranslateKey = 'folders.fields.assessment_date'
  if (['CEOM'].includes(profile?.company?.name)) {
    assessment_dateTranslateKey += '_ceom'
  }

  let patientTranslateKey = 'folders.fields.patient'
  if (['CEOM'].includes(profile?.company?.name)) {
    patientTranslateKey += '_ceom'
  }

  const { messageApi } = useUI()

  const downloadReport = async () => {
    setDownloadLoading(true)
    const { data, error } = await supabase
      .storage
      .from('folders')
      .download(values.report_path)

    if (data) {
      const blobUrl = window.URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = report_path; // Set your desired filename and extension here
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(blobUrl);
    } else {
      messageApi.open({
        type: 'error',
        content: error.message,
        duration: 4,
      })
    }

    setDownloadLoading(false)
  }

  const renderField = (field, renderReference = true) => {
    return <div className='box-border flex w-full flex-col gap-4'>
      {!isInline && !isUpdate && <Form.Item name={[field.name, "type"]} initialValue="transcribe">
        <Radio.Group className='flex'>
          <Radio.Button className='flex-grow text-center' value="transcribe">{t('folders.fields.type-values.transcribe')}</Radio.Button>
          <Radio.Button className='flex-grow text-center' value="translate">{t('folders.fields.type-values.translate')}</Radio.Button>
        </Radio.Group>
      </Form.Item>}

      {!!folders && (folders[field.fieldKey]?.type === 'translate') &&
        <Label label={t('folders.form.translate_to')} icon={light('language')}>
          <Form.Item name={[field.name, "translate_to"]} rules={[{ required: true, message: false }]}>
            <Select
              variant={isInline ? "filled" : "outlined"}
              disabled={isUpdate}
              allowClear
              options={[{ label: t('langs.fr'), value: 'fr' }, { label: t('langs.en'), value: 'en' }]}
            />
          </Form.Item>
        </Label>
      }

      <div className="flex gap-4">
        <Label label={t(patientTranslateKey)} icon={light('user')}>
          <Form.Item name={[field.name, "patient"]}>
            <Input variant={isInline ? "filled" : "outlined"} disabled={isCompleted || disabled} readOnly={readOnly} />
          </Form.Item>
        </Label>

        <Label label={t(assessment_dateTranslateKey)} icon={light('calendar')}>
          <Form.Item name={[field.name, "assessment_date"]}>
            <DatePicker variant={isInline ? "filled" : "outlined"} disabled={isCompleted || disabled} readOnly={readOnly} className='w-full' format="DD/MM/YYYY" placeholder={t('form.select-date')} />
          </Form.Item>
        </Label>
      </div>

      {<div className="flex gap-4">
        {!session.user.user_metadata.doctor_id && (
          <Label label={t('folders.fields.doctor')} icon={light('user-doctor')}>
            <Form.Item name={[field.name, "doctor_id"]} rules={[{ required: true, message: false }]}>
              <DoctorSelect variant={isInline ? "filled" : "outlined"} disabled={isUpdate} options={doctors} afterChange={(value) => {
                if (!isUpdate && profile.universus && !!value.length) {
                  const doctor = doctors.find(d => String(d.value) === String(value[0]))
                  if (doctor?.doctor?.specialities?.length) {
                    form.setFieldValue(["folders", field.name, 'speciality_id'], doctor.doctor.specialities[0])
                  }
                }
              }} />
            </Form.Item>
          </Label>
        )}
        {renderReference && <Label label={t('folders.fields.reference')} icon={light('hashtag')}>
          <Form.Item name={[field.name, "reference"]}>
            <Input variant={isInline ? "filled" : "outlined"} disabled={isCompleted || disabled} readOnly={readOnly} autoComplete="off" />
          </Form.Item>
        </Label>}
      </div>}

      <Label label={t('folders.fields.comments')} icon={light('memo-circle-info')}>
        <Form.Item
          name={[field.name, "comments"]}
        >
          <Textarea variant={isInline ? "filled" : "outlined"} disabled={isCompleted || disabled} readOnly={readOnly} />
        </Form.Item>
      </Label>
      {!isInline && isCompleted && filesLoading && <div className='flex w-full items-center justify-center mt-2'>
        <Spin size="large" />
      </div>}
      {!isInline && !isCompleted && <Form.Item
        valuePropName="fileList"
        name={[field.name, "files"]}
        getValueFromEvent={(e) => {
          if (Array.isArray(e)) {
            return e;
          }
          return e?.fileList;
        }}
      >
        <Dragger
          disabled={filesLoading}
          multiple
          style={isCompleted && { display: 'none' }}
          itemRender={(originNode, file) => {
            const removeFile = () => {
              form.setFieldValue('folders', folders.map((folder, index) => {
                if (index === field.fieldKey) {
                  return {
                    ...folder,
                    files: folder.files.filter(f => f.uid !== file.uid)
                  }
                }

                return folder
              }))
            }

            return (
              <div className='flex items-center gap-2'>
                <FontAwesomeIcon icon={light('paperclip')} />
                {file.name}
                {!isCompleted && !file.id && <Tooltip title={t('remove')} placement={"left"} arrow={false}>
                  <Popconfirm
                    arrow={false}
                    rootClassName="elevated pr-4"
                    placement={'left'}
                    title={t('folders.form.remove-file')}
                    okText={t('yes')}
                    cancelText={t('no')}
                    onConfirm={removeFile}
                  >
                    <Button className='ml-auto' size="small" icon={<FontAwesomeIcon icon={solid('trash')} />} type="text"></Button>
                  </Popconfirm>
                </Tooltip>}
              </div>
            )
          }}
          beforeUpload={(file, files) => false}
        >
          {<div className='flex gap-4 items-center'>
            <div style={{ minWidth: '54px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              {filesLoading && <Spin size="large" />}
              {!filesLoading && <FontAwesomeIcon icon={light('cloud-arrow-up')} className='text-label' size="3x" />}
            </div>
            <div className='flex flex-col text-left'>
              <p className="ant-upload-text text-label">{t('folders.form.upload-title')}</p>
              <p className="ant-upload-hint text-left m-0">
                {t('folders.form.upload-text')}
              </p>
            </div>
          </div>}
        </Dragger>
      </Form.Item>}

      {profile.universus && specialities && <Label label={t('folders.fields.speciality')} icon={light('shapes')}>
        <Form.Item name={[field.name, "speciality_id"]}>
          <Select
            variant={isInline ? "filled" : "outlined"}
            disabled={isCompleted || disabled} readOnly={readOnly}
            allowClear
            options={specialities.map((item) => ({ label: item.name, value: item.id }))}
          />
        </Form.Item>
      </Label>}

      {profile.universus && (
        <Label label={"Tags"} icon={light('tags')} info={"Medaca (SFU, TMHA)"}>
          <Form.Item name={[field.name, "tags"]}>
            <Select
              disabled={isCompleted || disabled} readOnly={readOnly}
              mode="tags"
              variant={isInline ? "filled" : "outlined"}
              allowClear
              options={[]}
            />
          </Form.Item>
        </Label>
      )}

      <Label label={t('folders.fields.emergency')} icon={light('siren-on')} info={t('folders.form.emergency')}>
        <Form.Item
          name={[field.name, "emergency"]}
          valuePropName="checked"
        >
          <Switch disabled={isCompleted || disabled} readOnly={readOnly} />
        </Form.Item>
      </Label>

      {profile.universus && values && values.folders[0].work && isUpdate && <>
        {<div className="flex gap-4">
          {values.folders[0].work.transcriber && (
            <Label label={t('folders.fields.transcriber')} icon={light('user-pen')}>
              <Input variant={isInline ? "filled" : "outlined"} value={values.folders[0].work.transcriber.profile.last_name + ' ' + values.folders[0].work.transcriber.profile.first_name} />
            </Label>
          )}
          {values.folders[0].work.reviewer && (
            <Label label={t('folders.fields.reviewer')} icon={light('user-magnifying-glass')}>
              <Input variant={isInline ? "filled" : "outlined"} value={values.folders[0].work.reviewer.profile.last_name + ' ' + values.folders[0].work.reviewer.profile.first_name} />
            </Label>
          )}
        </div>}

        {values.folders[0].work.translater && (
          <Label label={t('folders.fields.translater')} icon={light('user-doctor-message')}>
            <Input variant={isInline ? "filled" : "outlined"} value={values.folders[0].work.translater.profile.last_name + ' ' + values.folders[0].work.translater.profile.first_name} />
          </Label>
        )}
      </>}
    </div>
  }

  const navigate = useNavigate();

  let status

  if (values.folders && values.folders[0].work && profile.universus) {
    if (values.folders[0].work.status === 'available' && values.folders[0].work.transcriber) {
      status = foldersStatusOptions['correction']
    } else if (values.folders[0].work.status === 'available' && !values.folders[0].work.transcriber) {
      status = foldersStatusOptions[values.folders[0].work.service]
    } else if (values.folders[0].work.status === 'ready') {
      status = foldersStatusOptions["report"]
    } else if (values.folders[0].work.status === 'ongoing') {
      if (!values.folders[0].work.reviewer) {
        status = foldersStatusOptions[values.folders[0].work.service]
      } else {
        status = foldersStatusOptions["correction"]
      }
    } else {
      status = foldersStatusOptions[values.folders[0].work.service]
    }
  } else {
    status = foldersStatusOptions[values.folders[0].status]
  }

  return (
    <>
      {/* {isInline && values && values.folders && values.folders[0].work && <Typography.Text type="secondary" strong style={{ fontSize: 14, userSelect: 'none' }}>
        <Tag bordered={false} icon={<FontAwesomeIcon className="mr-1.5" icon={status.icon} />} color={"default"}>
          {t(status.label)}
        </Tag>
      </Typography.Text>} */}

      {(isUpdate) && <Form.Item name={["id"]} className='hidden'><Input /></Form.Item>}
      {!profile.universus && <Form.Item name={["company_id"]} className='hidden'><Input /></Form.Item>}

      {isCompleted && report_path && !isInline && <Button disabled={downloadLoading} loading={downloadLoading} onClick={downloadReport} type="primary" block icon={<FontAwesomeIcon icon={solid('cloud-arrow-down')} />}>{t('folders.form.download-report')}</Button>}

      {(!isInline && (profile.universus && companies)) && (
        <Label label={t('folders.fields.company')} icon={light('hospital')}>
          <Form.Item name='company_id' rules={[{ required: true, message: false }]}>
            <Select
              variant={isInline ? "filled" : "outlined"}
              disabled={disabled || readOnly || isUpdate}
              allowClear
              options={companies.map((item) => ({ label: item.name, value: item.id }))}
              onChange={(id) => {
                form.setFieldValue('folders', [{}])
                form.setFieldValue('company_id', id)
              }}
            />
          </Form.Item>
        </Label>
      )}

      {(isInline || (!profile.universus || !!company_id)) && <Form.List name="folders">
        {(fields, { add, remove }) => {
          return (
            <div className='flex flex-col gap-5'>
              {(isInline || (!!folders && fields.length === 1)) && renderField(fields[0])}

              {fields.length > 1 && <Collapse
                onChange={(key) => setActiveKey(key)}
                activeKey={activeKey}
                items={fields.map((field, key) => ({
                  key: field.name,
                  label: <Form.Item disabled={isCompleted || disabled} readOnly={readOnly} name={[field.name, "reference"]} className='pr-3'>
                    <Input variant={isInline ? "filled" : "outlined"} autoComplete="off" autoFocus placeholder={t('folders.fields.reference')} onClick={(e) => e.stopPropagation()} />
                  </Form.Item>,
                  children: renderField(field, false),
                  extra: <Tooltip title={t('remove')} placement={"left"} arrow={false}>
                    <Button onClick={(e) => [e.stopPropagation(), remove(field.name)]} icon={<FontAwesomeIcon icon={solid('trash')} type="text" />} />
                  </Tooltip>,
                }))}
              />}

              {!isUpdate && !isInline && <Button type="dashed" onClick={() => [addFolder(add), setActiveKey(fields.length)]} block icon={<FontAwesomeIcon icon={solid('add')} />}>
                {t('folders.form.new-folder')}
              </Button>}
            </div>
          )
        }}
      </Form.List>}

      {isInline && ((profile.universus && companies)) && (
        <Label label={t('folders.fields.company')} icon={light('hospital')}>
          <Form.Item name='company_id' rules={[{ required: true, message: false }]}>
            <Select
              variant={isInline ? "filled" : "outlined"}
              disabled={disabled || readOnly || isUpdate}
              allowClear
              options={companies.map((item) => ({ label: item.name, value: item.id }))}
              onChange={(id) => {
                form.setFieldValue('folders', [{}])
                form.setFieldValue('company_id', id)
              }}
            />
          </Form.Item>
        </Label>
      )}

      {isInline && !isCompleted && <PageContext.Provider value={{ update, remove, translateKey: 'folders', openForm: () => alert(), menus: [] }}><FormActions
        isMutationLoading={isMutationLoading}
        outlined
        onClose={() => navigate('/')}
        isUpdate={isUpdate}
        initialValues={values}
      /></PageContext.Provider>}

      {!isInline && !isCompleted && <FormActions
        isMutationLoading={isMutationLoading}
        onClose={onClose}
        isUpdate={isUpdate}
        initialValues={values}
      />}
    </>
  )
}

const DoctorSelect = ({ options, onChange, value, disabled, afterChange, variant }) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const handleDoctorChange = (value) => {
    setSearchValue('')
    afterChange(value)
    onChange(value.slice(-1))
    setOpen(false)
  };

  const handleDropdownVisibleChange = (flag) => {
    setOpen(flag)
  };

  const showSearch = !value || !value.length

  return <SelectAnt
    variant={variant}
    mode="tags"
    onChange={handleDoctorChange}
    allowClear
    open={open}
    onDropdownVisibleChange={handleDropdownVisibleChange}
    tagRender={({ label, value }) => {
      const onPreventMouseDown = (event) => {
        event.preventDefault();
        event.stopPropagation();
      };

      return <Tag
        onMouseDown={onPreventMouseDown}
        style={{ marginInlineEnd: 4 }}
      >
        {label}
      </Tag>
    }}
    value={value}
    optionRender={(option) => {
      if (!option.data.doctor) {
        return <div className='flex items-center' style={{ paddingLeft: 10 }}>
          <FontAwesomeIcon style={{ marginRight: 16 }} icon={light('add')} /> <span><strong>{option.value}</strong></span>
        </div>
      }

      const name = option.label.replace('Dre', '').replace('Dr', '').trim()

      return <div className="h-full w-full flex gap-2 items-center">
        <Avatar profile={{ last_name: name }} size={30} />
        {name}
      </div>
    }}
    onInputKeyDown={value?.length ? () => false : undefined}
    showSearch={showSearch}
    options={options}
    disabled={disabled}
    onSearch={(value) => showSearch && setSearchValue(value)}
    searchValue={searchValue}
    placeholder={t('form.empty-field')}
    optionFilterProp="label"
    filterOption={(input, option) => {
      return (option?.label ?? '')
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
        .includes(
          input
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
        )
    }
    }
  />
}

export const foldersStatusOptions = {
  processing: { label: 'folders.fields.status-values.processing', value: 'processing', icon: regular('ellipsis'), color: 'default' },
  translate: { label: 'folders.fields.status-values.translate', value: 'translate', icon: regular('language'), color: 'volcano' },
  transcribe: { label: 'folders.fields.status-values.transcribe', value: 'transcribe', icon: regular('pencil'), color: 'gold' },
  correction: { label: 'folders.fields.status-values.correction', value: 'correction', icon: regular('magnifying-glass'), color: 'purple' },
  report: { label: 'folders.fields.status-values.report', value: 'report', icon: regular('envelope'), color: 'blue' },
  inprogress: { label: 'folders.fields.status-values.inprogress', value: 'inprogress', icon: regular('folder-grid'), color: 'processing' },
  reviewing: { label: 'folders.fields.status-values.reviewing', value: 'reviewing', icon: regular('folder-magnifying-glass'), color: 'warning' },
  completed: { label: 'folders.fields.status-values.completed', value: 'completed', icon: regular('folder-check'), color: 'success' },
}
