import clsx from 'clsx'
import Navigation from 'src/@components/layout/Navigation'
import { useLocation } from 'react-router-dom'
import { useUI } from 'src/layout/UI'
import { useProfile } from 'src/profiles/ProfilesApi'
import Teams from 'src/teams/Teams'

export default function Layout({ children }) {
  const { contextHolder } = useUI()
  const { pathname } = useLocation()
  const { data: profile } = useProfile()

  return (
    <div className="box-border flex h-screen w-screen flex-row overflow-hidden bg-layout p-3 pt-0 relative">
      {contextHolder}

      <div className="flex h-full grow flex-col">
        <Navigation />
        <div className="flex grow">
          <div
            style={!pathname.includes('transcription') && !pathname.includes('order') && !pathname.includes('report') ? { boxShadow: "0 1px 2px 0 rgb(0 0 0 / 0.05)" } : {}}
            className={clsx("relative flex max-w-full grow rounded-lg gap-2", !pathname.includes('transcription') && !pathname.includes('order') && !pathname.includes('report') && "bg-base shadow-md")}
          >
            {profile?.universus && <Teams />}
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}
