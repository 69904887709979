import { useMemo } from 'react'

import { useUI } from 'src/layout/UI'
import { useSettings } from 'src/settings/SettingsApi'

import { Eventcalendar, locale } from '@mobiscroll/react'
import '@mobiscroll/react/dist/css/mobiscroll.min.css'
import './Calendar.css'
import CalendarHeader from './CalendarHeader'
import { DateTime } from 'luxon'
import { Link, useNavigate } from 'react-router-dom'

export default function CalendarMonth({ filterDate, setFilterDate, events }) {
  const { darkMode } = useUI()
  const { data: settings } = useSettings()
  const navigate = useNavigate()

  const view = useMemo(
    () => ({
      calendar: {
        labels: true,
        type: 'month'
      },
    }),
    [settings.week_monday]
  )

  const onPageChange = ({ month }) => {
    setFilterDate(DateTime.fromJSDate(month))
  }

  return (
    <Eventcalendar
      data={events}
      onPageChange={onPageChange}
      locale={locale[settings.lang]}
      view={view}
      selectedDate={filterDate}
      onEventClick={(args) => {
        navigate(`/order/${args.event.payload.pydio_id}`)
      }}
      renderHeader={(e) => (
        <CalendarHeader viewType={'month'} setFilterDate={setFilterDate} filterDate={filterDate} />
      )}
      themeVariant={darkMode ? 'dark' : 'light'}
      theme="material"
      touchUi={'auto'}
    />
  )
}
