import { BrowserRouter, Route, Routes, Navigate, useLocation } from 'react-router-dom'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import { AuthProvider, useAuth } from 'src/auth/Auth'
import { UIProvider } from 'src/layout/UI'

import Layout from 'src/layout/Layout'
import SignIn from 'src/auth/SignIn'
import SignUp from 'src/auth/SignUp'
import ForgotPassword from 'src/auth/ForgotPassword'
import FoldersPage from 'src/folders/FoldersPage'
import MissingDocumentsCancel from 'src/missing-documents/MissingDocumentsCancel';
import TranscriptionPage from 'src/transcription/TranscriptionPage'
import Teams from 'src/teams/Teams'
import CalendarPage from 'src/calendar/CalendarPage'

const queryClient = new QueryClient()

export default function App() {
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <UIProvider>
          <AuthProvider>
            <Routes>
              <Route path="/sign-in" element={<SignIn />} />
              <Route path="/sign-up/:token" element={<SignUp />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />


              <Route path="/" element={<Navigate to="/inprogress" replace />} />

              <Route path="/teams" element={<ProtectedRoute><div></div></ProtectedRoute>} />
              
              <Route path="/order/:folder" element={<ProtectedRoute><TranscriptionPage /></ProtectedRoute>} />
              <Route path="/transcription/:folder" element={<ProtectedRoute><TranscriptionPage /></ProtectedRoute>} />
              <Route path="/report/:folder" element={<ProtectedRoute><TranscriptionPage /></ProtectedRoute>} />

              <Route path="/calendar/day" element={<ProtectedRoute><CalendarPage /></ProtectedRoute>} />
              <Route path="/calendar/week" element={<ProtectedRoute><CalendarPage /></ProtectedRoute>} />
              <Route path="/calendar/month" element={<ProtectedRoute><CalendarPage /></ProtectedRoute>} />
              
              <Route path="/upcoming/:folder?" element={<ProtectedRoute><FoldersPage /></ProtectedRoute>} />
              <Route path="/validation/:folder?" element={<ProtectedRoute><FoldersPage /></ProtectedRoute>} />
              <Route path="/inprogress/:folder?" element={<ProtectedRoute><FoldersPage /></ProtectedRoute>} />
              <Route path="/completed/:folder?" element={<ProtectedRoute><FoldersPage /></ProtectedRoute>} />
              <Route path="/tocorrect/:folder?" element={<ProtectedRoute><FoldersPage /></ProtectedRoute>} />
              <Route path="/available/:folder?" element={<ProtectedRoute><FoldersPage /></ProtectedRoute>} />
              <Route path="/tovalidate/:folder?" element={<ProtectedRoute><FoldersPage /></ProtectedRoute>} />
              <Route path="/totranslate/:folder?" element={<ProtectedRoute><FoldersPage /></ProtectedRoute>} />

              <Route path="/missing-documents/cancel" element={<MissingDocumentsCancel />} />
            </Routes>
          </AuthProvider>
        </UIProvider>
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      </QueryClientProvider>
    </BrowserRouter>
  )
}

const ProtectedRoute = ({ children }) => {
  const { session } = useAuth()
  const { pathname } = useLocation()

  if (!session) {
    return <Navigate to="/sign-in" state={{ pathname }} />
  }

  return <Layout>{children}</Layout>
}
