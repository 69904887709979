import { duotone, light, regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Badge, Button, Dropdown, Segmented, Typography } from 'antd'
import clsx from 'clsx'
import { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Link, useNavigate } from 'react-router-dom'
import MenuButton from 'src/@components/MenuButton'
import logo from 'src/assets/universus.png'
import { useAuth } from 'src/auth/Auth'
import { useUI } from 'src/layout/UI'
import { useProfile, useTranscriber } from 'src/profiles/ProfilesApi'
import { useSettings, useUpdateSettings } from 'src/settings/SettingsApi'
import { supabase } from 'src/supabase'
import Teams from 'src/teams/Teams'
import TeamsNavigation from 'src/teams/TeamsNavigation'

const { Text } = Typography

export default function Navigation() {
  const location = useLocation()
  const navigate = useNavigate()

  const { lang, setBackUrl, logoPreview } = useUI()
  const { toggleSettings, isSettingsOpen } = useAuth()
  const { data: transcriber, refetch: fetchTranscriber } = useTranscriber()
  const { data: profile, refetch: fetchProfile } = useProfile()
  const { data: settings, refetch: fetchSettings } = useSettings()
  const { mutate: updateSettings } = useUpdateSettings()

  useEffect(() => {
    [fetchProfile(), fetchSettings(), fetchTranscriber()]
  }, [])

  useEffect(() => {
    if (settings.id && !settings.lang) {
      updateSettings({ lang })
    }
  }, [settings])

  useEffect(() => {
    if (profile && settings?.permissions) {
      if (profile.universus && !settings?.permissions['read-folders'] && location.pathname.includes('inprogress')) {
        navigate('/available')
      }
    }
  }, [profile, settings])

  const freezeTranscription = useMemo(() => {
    if (profile && settings?.permissions) {
      if (
        profile.universus
        && !settings?.permissions['read-folders']
        && transcriber?.current
      ) {
        return true
      }
    }

    return false
  }, [transcriber, location.pathname, settings])

  useEffect(() => {
    if (freezeTranscription && !location.pathname.includes('transcription') && !location.pathname.includes('teams')) {
      navigate('/transcription/' + transcriber.current.pydio_id)
    }
  }, [freezeTranscription, location.pathname])

  const homeTo = useMemo(() => {
    let url
    if (profile && settings) {
      if (freezeTranscription) {
        url = '/transcription/' + transcriber?.current?.pydio_id
      } else if (!profile.universus || (settings?.permissions && settings?.permissions['read-folders'])) {
        url = '/inprogress'
      } else {
        url = '/available'
      }
    }

    return url
  }, [profile, settings, freezeTranscription])

  const backTo = useMemo(() => {
    let url
    if (window.history) {
      if (!!window.history.state.idx) {
        url = 'back'
      } else {
        return homeTo
      }

      return url
    }
  }, [location.pathname])

  useEffect(() => setBackUrl(backTo), [backTo])

  const [preview, setPreview] = useState(null)

  useEffect(() => {
    if (location.pathname.includes('calendar')) {
      setPreview('calendar')
    } else if (location.pathname.includes('order')) {
      setPreview(null)
    } else {
      setPreview('table')
    }
  }, [location.pathname])

  return (
    <div className="flex h-14 w-full justify-between flex-shrink-0">
      <Link to={homeTo}>
        <div className="flex h-full items-center gap-3 cursor-pointer select-none">
          <img src={logo} className='w-8 h-8 logo' />
          {profile && <div className='flex flex-col gap-1'>
            <Text strong style={{
              fontSize: 16,
              fontOpticalSizing: "auto",
              fontWeight: 600,
              fontStyle: "normal",
              lineHeight: 1
            }}>
              {"UNIVERSUS"}
            </Text>
            {/* {<Text type="secondary" style={{
                fontSize: 10,
                fontFamily: '"Raleway", sans-serif',
                fontOpticalSizing: "auto",
                fontWeight: 400,
                fontStyle: "normal",
                lineHeight: 1
              }}>
                ⟟₪ Đℝ∑⟑Mš ₩€ ₸ℝ⊔$⟘
              </Text>} */}
          </div>}
        </div>
      </Link>

      {profile?.isPreview && <div className='flex h-full items-center' style={{ transform: 'scale(1.05)' }}>
        <Segmented
          value={preview}
          options={[
            {
              label: (
                <div className='flex items-center gap-2 p-1'>
                  <FontAwesomeIcon icon={regular('table-rows')} />
                  <Typography.Text>Liste</Typography.Text>
                </div>
              ),
              value: 'table',
            },
            {
              label: (
                <div className='flex items-center gap-2 p-1'>
                  <FontAwesomeIcon icon={regular('calendar')} />
                  <Typography.Text>Calendrier</Typography.Text>
                </div>
              ),
              value: 'calendar',
            },]}
          onChange={(value) => {
            if (value === 'table') {
              navigate('/inprogress')
            } else {
              navigate('/calendar/day')
            }
          }}
        />
      </div>}
      <div className={clsx('flex flex items-center', !profile?.universus ? 'gap-2' : 'gap-0.5')}>
        {profile?.universus && <TeamsNavigation />}

        {/* <Badge dot offset={[-6, 6]}>
          <MenuButton label="notifications" icon={light('bell')} iconActive={solid('bell')} />
        </Badge> */}
        {(profile?.company || logoPreview) && <>
          {(logoPreview || profile.company?.logo) && <img style={{ height: 28 }} src={logoPreview || profile.company.logo} />}
          {!profile.universus && !profile.company?.logo && <Text strong style={{
            fontSize: 16,
            fontOpticalSizing: "auto",
            fontWeight: 600,
            fontStyle: "normal",
            lineHeight: 1
          }}>
            {profile.company?.name}
          </Text>}
        </>}
        <MenuButton onClick={() => toggleSettings()} label="settings" isActive={isSettingsOpen} icon={light('gear')} iconActive={solid('gear')} />
      </div>
    </div>
  )
}
