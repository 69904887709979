import { useMemo, useRef } from 'react'

import { useUI } from 'src/layout/UI'
import { useSettings } from 'src/settings/SettingsApi'

import { Eventcalendar, locale } from '@mobiscroll/react'
import '@mobiscroll/react/dist/css/mobiscroll.min.css'
import './Calendar.css'
import CalendarHeader from './CalendarHeader'
import { Link, useNavigate } from 'react-router-dom'

import clsx from 'clsx'
import { theme } from 'antd'

const { useToken } = theme

export default function CalendarDays({ section, openForm, filterDate, setFilterDate, events }) {
  const { darkMode } = useUI()
  const { token } = useToken()
  const { data: settings } = useSettings()
  const navigate = useNavigate()

  const calendarRef = useRef()

  const view = useMemo(
    () => ({
      schedule: {
        type: section.value,
        startDay: 1,
        endDay: 5,
        startTime: '07:00',
        endTime: '19:00',
        allDay: false,
      },
    }),
    [section, settings.week_monday]
  )

  return (
    <Eventcalendar
      data={events}
      locale={locale[settings.lang]}
      clickToCreate={false}
      dragToCreate={false}
      dragToMove={false}
      dragToResize={false}
      ref={calendarRef}
      extendDefaultEvent={() => ({
        title: 'New order',
        color: token.colorPrimaryActive,
      })}
      onEventClick={(args) => {
        navigate(`/order/${args.event.payload.pydio_id}`)
      }}
      onEventCreate={() => openForm()}
      className={clsx('full-calendar', view.schedule.type === 'day' && 'view-day')}
      renderHeader={(e) => (
        <CalendarHeader viewType={view.schedule.type} setFilterDate={setFilterDate} filterDate={filterDate} />
      )}
      selectedDate={filterDate}
      view={view}
      themeVariant={darkMode ? 'dark' : 'light'}
      theme="material"
      touchUi={true}
    />
  )
}
